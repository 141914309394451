import React, {useState, useEffect} from 'react';
import { signInWithEmailAndPassword, getAdditionalUserInfo } from 'firebase/auth';
import { auth } from '../firebase.js';
import ResponsiveAppBar from '../components/ResponsiveAppBar';
import Grid from '@mui/material/Grid';
import { getAuth, signInWithPopup, signInWithRedirect, getRedirectResult, GoogleAuthProvider } from "firebase/auth";
import GoogleButton from 'react-google-button'
import { useNavigate } from "react-router-dom";

export default function Login() {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({   
        prompt : "select_account "
    });
    
    const signInWithGooglePopup = () => signInWithPopup(auth, provider);
    const navigate = useNavigate();

    const logGoogleUser = async () => {
        signInWithGooglePopup().then((result) => {
            console.log("getting result", result)
            if (result === null) {
                return
            }
            // This gives you a Google Access Token. You can use it to access Google APIs.
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const additionalUserInfo = getAdditionalUserInfo(result)

            // The signed-in user info.
            const user = result.user;
            const token = user.accessToken;

            console.log("additionalUserInfo: ", additionalUserInfo)

            if (additionalUserInfo.isNewUser) {
                console.log("Signing up new user")
                fetch("/api/users", {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        userID: token,
                        username: user.email,
                    })
                })
            }
            navigate("/")
          }).catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            // const email = error.customData.email;
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
            console.log("fail", errorMessage, credential)
          });;
    }

    return(
        <>
        <ResponsiveAppBar/>


        <GoogleButton className="class-form-container" onClick={logGoogleUser }/> 
        </>
    )
}
