import React, { useState, useEffect } from 'react';
import { onAuthStateChanged } from "firebase/auth";
import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom';
import ResponsiveAppBar from '../components/ResponsiveAppBar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


const ConfirmationPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    console.log(t, t('welcome'))
    auth.authStateReady().then(() => {
      // Do whatever you want here ...
      var user = auth.currentUser;
      if (user !== null) {
        setIsLoggedIn(true)
      } else {
        setIsLoggedIn(false)
      }
    })
  })

  const handleGoBack = () => {
    navigate("/classes")
  };

  return (
    <>
      <ResponsiveAppBar/>

        <Button
          className="backButton"
          onClick={handleGoBack}
          startIcon={<ArrowBackIcon />}
          variant="contained"
          color="primary"
        >
          {t("lessons_page.go_to_all_classes")}
        </Button>

        <div className="header-container">
        { t('payment_form.approve') }
        </div>
    </>
  )
}
 
export default ConfirmationPage