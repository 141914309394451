import ResponsiveAppBar from '../components/ResponsiveAppBar.js';
import LessonCard from '../components/LessonCard.js';
import AddLessonCard from '../components/AddLessonCard.js';
import '../components/Header.css'
import '../components/backButton.css';
import '../components/settingsButton.css';
import { auth } from '../firebase';

import {useState, useEffect} from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import SettingsIcon from '@mui/icons-material/Settings';
import { useTranslation } from 'react-i18next';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const Lessons = () => {
  const params = useParams();
  const [userEmail, setUserEmail] = useState('');
  const [userToken, setUserToken] = useState('');
  const [lessons, setLessons] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isPaid, setIsPaid] = useState(false);
  const [numberOfLessons, setNumberOfLessons] = useState(0);
  const [className, setClassName] = useState([]);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    auth.authStateReady().then(() => {
      // Do whatever you want here ...
      var user = auth.currentUser;
      if (user !== null) {
        setUserEmail(user.email)
        setUserToken(user.accessToken)
        console.log("fetching")
        fetch("/api/class/" + params.class_id , {
		        method: 'GET',
		        headers: {
		            'Accept': 'application/json',
		            'Content-Type': 'application/json',
		            'username': user.email,
		            'userid': user.accessToken,
		        }
		        // TODO: better
		    }).then((res) => {
		    	if (res.status != 200) {
		    		console.log("Status was", res.status, "Error")
		    	} else {
			    	res.json().then((res) => {
				    	console.log("got class data", res, "from server")
				    	setLessons(res['LessonList'])
				    	setClassName(res['Name'])
				    	if (res['AdminList'].includes(user.email)) {
				    		setIsAdmin(true)
				    	}
				    })
		    	}
		    }).catch(rejected => {
					console.log(rejected);
				});

				fetch("/api/users/" + user.email, {
		        method: 'GET',
		        headers: {
		            'Accept': 'application/json',
		            'Content-Type': 'application/json',
		            'username': user.email,
		            'userid': user.accessToken,
		        }
		    }).then((res) => {
		    	if (res.status != 200) {
		    		console.log("Status was", res.status, "Error")
		    	} else {
			    	res.json().then((res) => {
				    	console.log("got user data", res, "from server")
				    	setNumberOfLessons(res["NumOfLessons"])
				    	if (res["PaymentToken"] !== null) {
				    		setIsPaid(true);
				    	}
				    })
		    	}
		    }).catch(rejected => {
					console.log(rejected);
				});
      } else {
      	console.log("User isn't logged in")
      }

    })
  }, [])

  const handleGoBack = () => {
    navigate("..")
  };
  const handleGoToSettings = () => {
    navigate("settings")
  };

	return (
		<>
			<ResponsiveAppBar/>

        <Button
          className="backButton"
          onClick={handleGoBack}
          startIcon={<ArrowBackIcon />}
          variant="contained"
          color="primary"
        >
          {t("lessons_page.go_to_all_classes")}
        </Button>
        <Button
          className="settingsButton"
          onClick={handleGoToSettings}
          startIcon={<SettingsIcon />}
          variant="contained"
          color="primary"
        >
          {t("lessons_page.go_to_settings")}
        </Button>
		    
			<div className="header-container">
			  <h1 className="header-title">{t("lessons_page.class_title_prefix")} "{className}"</h1>
			</div>

	    <Box sx={{ margin: "0% 5% 5% 5%", flexGrow: 1 }}>

	      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
	      	{
	      		isAdmin && 
            <Grid item xs={2} sm={4} md={4} key={0}>
        	  	<AddLessonCard number_of_lessons={numberOfLessons} isPaid={isPaid}/>
            </Grid>
          }
	        {Array.from(lessons).map((lesson_obj, index) => (
	          <Grid item xs={2} sm={4} md={4} key={index}>
	            <LessonCard lesson_id={lesson_obj.LessonID} name={lesson_obj.LessonName} admin={lesson_obj.Admin} videoLink={lesson_obj.VideoLink} isAdmin={isAdmin} classId={params.class_id}/>
	          </Grid>
	        ))}
	      </Grid>
	    </Box>
		</>
		)
}

export default Lessons
