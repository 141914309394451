import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import './videoView.css';

const TIME_DIFF_MARGIN_ERROR = 1.1

const NonSkippableVideo = ({ url, handleVideoProgress }) => {
    const [playedSeconds, setPlayedSeconds] = useState(1.0);
    const [ref, setRef] = useState(null);

    const handleProgress = (state) => {
        console.log('Video onProgress', state)
        if (state.playedSeconds > playedSeconds + TIME_DIFF_MARGIN_ERROR) {
            // Todo: pop up of invalid progress
            console.log("Too fast progress")
            ref.seekTo(playedSeconds, 'seconds')
        }
        else {
            setPlayedSeconds(state.playedSeconds)
            handleVideoProgress(state.playedSeconds)
        }
    }

    return (
        <div className="videoView">
            <ReactPlayer
                className="videoView"
                id="nonSkippableVideo"
                url={url}
                ref={setRef}
                controls={true}
                onProgress={handleProgress}
            />
        </div>
    );
};

export default NonSkippableVideo;

