import React from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

// Inline CSS for the footer
const footerStyle = {
  bottom: 0,
  backgroundColor: '#f1f1f1',
  textAlign: 'center',
  backgroundAttachment: 'scroll',
  backgroundPosition: '0% 0%',
  position: 'fixed', 
  left: '0',
  bottom: '0',
  width: '100%',
  height: '80px',
  boxShadow: '0 -1px 5px rgba(0, 0, 0, 0.1)',
};

const linkStyle = {
  color: '#007bff',
  textDecoration: 'none',
  marginLeft: '10px',
};




const Footer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onClick = () => {
    navigate("/contact_us/")
  }

  return (
    <footer style={footerStyle}>
    <p>
        <a onClick={onClick} style={linkStyle}>{t("footer.contact_us_text")}</a>
        <br/>
        &copy; {new Date().getFullYear()} Verivid. All rights reserved.
      </p>
    </footer>
  );
};

export default Footer;
