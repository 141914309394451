import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Button, Grid, Box } from '@mui/material';
import { auth } from '../firebase';
import { useTranslation } from 'react-i18next';

import ResponsiveAppBar from '../components/ResponsiveAppBar.js';


import {
    PayPalScriptProvider,
    PayPalButtons,
    usePayPalScriptReducer
} from "@paypal/react-paypal-js";

const ButtonWrapper = ({ type, selectedPlan, userEmail, userToken, onErrorGUI, onApproveGUI }) => {
    const [{ options }, dispatch] = usePayPalScriptReducer();


    const paypalOnError = (err) => {
      console.log("Error")
      onErrorGUI()
    }
    const paypalOnApprove = (data, detail) => {
      // call the backend api to store transaction details
      console.log("Payapl approved")
      console.log(data.subscriptionID)

      fetch("/api/process-payment", {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'username': userEmail,
            'userid': userToken,
        },
        body: JSON.stringify({
            paymentToken: data.subscriptionID
        })
      }).then((res) => {
        if (res.status != 200) {
          console.log("Status was", res.status, "Error")
          onErrorGUI()
        } else {
          res.json().then((res) => {
            console.log("got", res, "from server")
            onApproveGUI()
          })
        }
      }).catch(rejected => {
        console.log(rejected);
        onErrorGUI()
      });
    };

    useEffect(() => {
        dispatch({
            type: "resetOptions",
            value: {
                ...options,
                intent: "subscription",
            },
        });
    }, [type]);

    return (<PayPalButtons
        createSubscription={(data, actions) => {
            return actions.subscription
                .create({
                  plan_id: selectedPlan === 'Monthly' ? 
                      process.env.REACT_APP_MONTHLY_PLAN_ID_LIVE : 
                      process.env.REACT_APP_MONTHLY_PLAN_ID_LIVE
                }).then((orderId) => {
                  // Your code here after create the order
                  console.log("Order id:")
                  return orderId;
                });
        }}
        onApprove={paypalOnApprove}
        catchError={paypalOnError}
        onError={paypalOnError}
        onCancel={paypalOnError}
        style={{
            label: "subscribe",
        }}
    />);
}

const PaymentForm = () => {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [userEmail, setUserEmail] = useState('');
  const [userToken, setUserToken] = useState('');
  const { t } = useTranslation();
  const [errorMsg, setErrorMsg] = useState(null);
  const [approveMsg, setApproveMsg] = useState(null);

  function onErrorGUI() {
    setErrorMsg(t("payment_form.error"))
  }
  function onApproveGUI() {
    setApproveMsg(t("payment_form.approve"))
    navigate("/confirmation_page")
  }

  useEffect(() => {
    auth.authStateReady().then(() => {
      // Do whatever you want here ...
      var user = auth.currentUser;
      if (user !== null) {
        setUserEmail(user.email)
        setUserToken(user.accessToken)
      } else {
      }
    })
  }, [])


  return (
    <>
      <ResponsiveAppBar/>

      <div className="header-container">
        <h1 className="header-title"> {t("payment_form.title")} </h1>
        <br/>
        {t("payment_form.header")}
      </div>
      {userEmail ? 
      <PayPalScriptProvider options={{ "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID_LIVE, 
                                       components: "buttons", 
                                       intent: "subscription",
                                       vault: true }}>
        <Box sx={{ flexGrow: 1, p: 2 }} >
          <Grid container spacing={2}>
            {['Monthly', 'Yearly'].map((plan) => (
              <Grid item xs={12} sm={6} key={plan}>
                <Card
                  sx={{
                    border: selectedPlan === plan ? '2px solid #1976d2' : '1px solid #ccc',
                    cursor: 'pointer',
                    transition: 'transform 0.3s, border-color 0.3s',
                    '&:hover': {
                      transform: 'scale(1.01)',
                      borderColor: '#1976d2',
                    },
                  }}
                  onClick={() => setSelectedPlan(plan)}
                >
                  <CardContent>
                    <Typography variant="h5" component="div">
                      {plan.charAt(0).toUpperCase() + plan.slice(1)} Plan
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      ₪{plan === 'Monthly' ? '1 ' + t("payment_form.per_month") : '10 ' + t("payment_form.per_year")} 
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
        <br/>
        {selectedPlan && (
          <Box sx={{ mt: 2, display: 'flex', alignItems: 'center',justifyContent: 'center' }}>
            <Box sx={{ width: '50%' }}>
              <ButtonWrapper type="subscription" selectedPlan={selectedPlan} userEmail={userEmail} userToken={userToken} onApproveGUI={onApproveGUI} onErrorGUI={onErrorGUI} />
            </Box>
          </Box>
        )}
        { approveMsg &&
          <div className="header-container">
          { approveMsg }
          </div>
        }
        { errorMsg &&
          <div className="header-container">
          { errorMsg }
          </div>
        }

      </PayPalScriptProvider>
      :
      <div className="header-container">
        {t("payment_form.needed_login")}
      </div>
      
      }
    </>
  );
};

export default PaymentForm;
