import React, { useState, useEffect } from 'react';
import { onAuthStateChanged } from "firebase/auth";
import { auth } from '../firebase';
import { useNavigate, useParams } from 'react-router-dom';
import ResponsiveAppBar from '../components/ResponsiveAppBar';
import '../components/backButton.css';

import List from '@mui/material/List';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import CommentIcon from '@mui/icons-material/Comment';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTranslation } from 'react-i18next';
import { DataGrid } from '@mui/x-data-grid';
import { useDemoData } from '@mui/x-data-grid-generator';
import { renderProgress } from '@mui/x-data-grid-generator/renderer/renderProgress';


function toHHMMSS(number) {
    var sec_num = parseInt(number, 10); // don't forget the second param
    var hours   = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    return hours+':'+minutes+':'+seconds;
}

export default function LessonStats() {
  const [seenUsers, setSeenUsers] = React.useState({});
  const [maxSeenValue, setMaxSeenValue] = React.useState(0);
  const [notSeenUsers, setNotSeenUsers] = React.useState([]);

  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);

  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [userEmail, setUserEmail] = useState('');
  const [userToken, setUserToken] = useState('');
  const [lessonName, setLessonName] = useState(null);
  const [lessonID, setLessonID] = useState('');
  const [videoLink, setVideoLink] = useState(null);

  useEffect(() => {
    auth.authStateReady().then(() => {
      var user = auth.currentUser;
      if (user !== null) {
        setUserEmail(user.email)
        setUserToken(user.accessToken)

        fetch("/api/class/" + 
               params.class_id + 
               "/lesson/" + 
               params.lesson_id, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'username': user.email,
                'userid': user.accessToken,
            }
            // TODO: better
        }).then((res) => {
          if (res.status != 200) {
            console.log("Status was", res.status, "Error")
          } else if (res.status == 404) {
            console.log("No such lesson")
          } else {
            res.json().then((res) => {
              console.log("got lesson stat", res, "from server")
              setLessonName(res['LessonName'])
              setLessonID(res['LessonID'])
              setVideoLink(res['VideoLink'])

              let tempMax = 0;
              for (let [key, value] of Object.entries(res['ProgressMap'])) {
                  if (value > tempMax) {
                    tempMax = Math.floor(value)
                  }
              }
              setMaxSeenValue(tempMax)

              const tempList = [];
              const tempNotSeenList = [];
              let counter = 1;
              for (let [key, value] of Object.entries(res['ProgressMap'])) {
                if (value === 0) {
                  tempNotSeenList.push(atob(key))
                } else {
                  let v = Math.floor(value)
                  tempList.push({id: counter, username: atob(key), percentage: (v / tempMax), time: toHHMMSS(v)})
                }
                counter += 1;
              }

              setSeenUsers(tempList)
              setNotSeenUsers(tempNotSeenList)
            })
          }
        }).catch(rejected => {
          console.log(rejected);
        });
        
      } else {
      }
    })
  }, [])

  const handleClearProgress = () => {
    let usernames = seenUsers.filter((dataItem) => {
      return rowSelectionModel.includes(dataItem.id)
    })
    usernames = usernames.map((dataItem) => dataItem.username)

    for (let username of usernames) {
      fetch("/api/class/" + params.class_id + "/lesson/" + 
                params.lesson_id + "/users/" + username, {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'username': userEmail,
              'userid': userToken,
          },
          body: JSON.stringify({
              progress: 0
          })
      }).then((response) => {
        if (!response.ok) {
          console.log("Error reseting lesson progress")
        } else {
        }
      }).catch(rejected => {
        console.log(rejected);
      });
    }
    
  }

  const handleGoBack = () => {
    navigate("..")
  };

  const columns: GridColDef[] = [
    { field: 'username', headerName: 'User name',  flex: 1 },
    { field: 'time', headerName: 'Time watched', flex: 1 },
    { field: 'percentage', type: "number", headerName: 'Percentage watched', flex: 1, renderCell: renderProgress}
  ];

  return (
    <>
      <ResponsiveAppBar/>
        <Button
          className="backButton"
          onClick={handleGoBack}
          startIcon={<ArrowBackIcon />}
          variant="contained"
          color="primary"
        >
          {t("lesson_stats_page.go_to_class_button")}
        </Button>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="up"
        sx={{ minHeight: '100vh' }}
      >
        <div className="header-container">
          { lessonName ?
            <h1 className="header-title">{t("lesson_stats_page.title_prefix")} "{lessonName}"</h1>
            :
            <h1 className="header-title">{t("lesson_stats_page.lesson_not_found")}</h1>
          }
        </div>

        { lessonName &&
          <>
            <div style={{gap: 10, display: "flex"}}>
            <Button variant="contained" 
                    onClick={() => {navigator.clipboard.writeText("https://verivid.app/watch_lesson/" + params.class_id + 
                                               "/" + 
                                               params.lesson_id)}}>
                    {t("lesson_stats_page.copy_link_button")}
            </Button>

            <Button variant="contained" 
                    onClick={() => {navigator.clipboard.writeText(params.class_id + 
                                               "/" + 
                                               params.lesson_id)}}>
                    {t("lesson_stats_page.copy_id_button")}
            </Button>
            </div>

          <h1>
            {t("lesson_stats_page.seen_title")}
          </h1>

          <div>
            <DataGrid 
              checkboxSelection 
              disableRowSelectionOnClick 
              autoHeight 
              rows={seenUsers} 
              columns={columns}
              onRowSelectionModelChange={(newRowSelectionModel) => {
                setRowSelectionModel(newRowSelectionModel);
              }}
              rowSelectionModel={rowSelectionModel}
            />
          </div>

          <br/>
          <Button variant="contained" 
                  onClick={handleClearProgress}
                  disabled={rowSelectionModel.length == 0}>
                 {t("lesson_stats_page.clear_progress")}
          </Button>
          <h1>
            {t("lesson_stats_page.unseen_title")}
          </h1>

          <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            {notSeenUsers.map((value) => {
              const labelId = `checkbox-list-label-${value}`;

              return (
                <ListItem
                  key={value}
                  
                >
                  <ListItemText id={labelId} primary={value} />
                </ListItem>
              );
            })}
          </List>
          </>
        }
      </Grid>
    </>
  )
}
