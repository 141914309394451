import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';

export default function AddClassCard({number_of_classes, isPaid}) {
  const [expanded, setExpanded] = React.useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const needToPay = !(isPaid || number_of_classes < process.env.REACT_APP_MAX_NUMBER_OF_CLASSES);

  const handleClick = () => {
    if (needToPay) {
      navigate("/pay")
    } else{
      navigate("new")
    }
  };

  return (
    <Card sx={{ maxWidth: 345, height: "30vh", margin: '0 auto'}}>
      <CardHeader
        action={
          <IconButton aria-label="add-class" onClick={handleClick}>
            <AddIcon/>
          </IconButton>
        }
        title={t("classes.add_class_card.title")}
      />
      <CardContent>
        {t("classes.add_class_card.amount_of_classes_prefix")} {number_of_classes} {t("classes.add_class_card.amount_of_classes_postfix")} 
        <br/>
        {!isPaid &&
          t("classes.add_class_card.max_number") + process.env.REACT_APP_MAX_NUMBER_OF_CLASSES
        }
        { needToPay &&
          <>
            <br/>
            <br/>
            <Button variant="contained" onClick={handleClick}>
              {t("classes.add_class_card.subscribe_message")}
            </Button>
          </>
        }
      </CardContent>
    </Card>
  );
}
