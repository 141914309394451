import React, { useState, useEffect } from 'react';
import { onAuthStateChanged } from "firebase/auth";
import { auth } from '../firebase';
import { useNavigate, useParams } from 'react-router-dom';
import ResponsiveAppBar from '../components/ResponsiveAppBar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Step from '../components/Step';
import NonSkippableVideo from '../components/NonSkippableVideo';
import '../components/videoView.css';


export default function WatchLesson() {
  const navigate = useNavigate();
  const params = useParams();
  const [userEmail, setUserEmail] = useState('');
  const [userToken, setUserToken] = useState('');
  const [videoLink, setVideoLink] = useState('');

  useEffect(() => {
    auth.authStateReady().then(() => {
      // Do whatever you want here ...
      var user = auth.currentUser;
      if (user !== null) {
        setUserEmail(user.email)
        setUserToken(user.accessToken)

        fetch("/api/class/" + params.class_id + "/lesson/" + params.lesson_id, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'username': user.email,
                'userid': user.accessToken,
            }
            // TODO: better
        }).then((res) => {
          if (res.status != 200) {
            console.log("Status was", res.status, "Error")
          } else {
            res.json().then((res) => {
              console.log("got video", res, "from server")
              setVideoLink(res.VideoLink)
            })
          }
        }).catch(rejected => {
          console.log(rejected);
        });
      } else {
      }
    })
  })


  function handleVideoProgress(playedSeconds) {
    fetch("/api/class/" + params.class_id + "/lesson/" + params.lesson_id + "/users", {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'username': userEmail,
            'userid': userToken,
        },
        body: JSON.stringify({
            progress: playedSeconds
        })
    }).catch(rejected => {
      console.log(rejected);
    });
  }


  return (
    <>
      <ResponsiveAppBar/>
      <NonSkippableVideo 
        className="videoView" 
        url={videoLink} 
        handleVideoProgress={handleVideoProgress}/>
    </>
  )
}
 