import React, { useState, useEffect } from 'react';
import ResponsiveAppBar from '../components/ResponsiveAppBar';
import './ClassForm.css';
import { useParams, useNavigate } from "react-router-dom";
import { auth } from '../firebase';

import '../components/backButton.css';
import '../components/videoView.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';

export default function CreateLesson() {
  const params = useParams();
  const [lessonName, setLessonName] = useState('');
  const [videoLink, setVideoLink] = useState('');
  const [users, setUsers] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [isPublicLesson, setIsPublicLesson] = useState(false); // State for advanced mode
  const [lessonError, setLessonError] = useState(false);
  const [ref, setRef] = useState(null);
  const [videoLoaded, setVideoLoaded] = useState(false);

  const handleReady = () => {
    console.log('Video is ready to play!');
    setVideoLoaded(true);
  };

  const [userEmail, setUserEmail] = useState('');
  const [userToken, setUserToken] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    auth.authStateReady().then(() => {
      // Do whatever you want here ...
      var user = auth.currentUser;
      if (user !== null) {
        setUserEmail(user.email)
        setUserToken(user.accessToken)
      } else {

      }
    })
  })

  useEffect(() => {
    setVideoLoaded(false)
  }, [videoLink])

  const handleSubmit = (e) => {
    e.preventDefault();
    var tmpLessonError = false;
    console.log("Creating a lesson")
    fetch("/api/class/" + params.class_id + "/lesson", {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'username': userEmail,
            'userid': userToken,
        },
        body: JSON.stringify({
            lessonName: lessonName,
            videoLink: videoLink,
            public: isPublicLesson
        })
    }).then((response) => {
      if (!response.ok) {
        setLessonError(true)
      } else {
        setLessonName('');
        setVideoLink('');
        setUsers([]);
        setAdmins([]);
        navigate("..");
      }
    }).catch(rejected => {
      console.log(rejected);
    });
  };

  const handleGoBack = () => {
    navigate("..")
  };

  return (
    <>
      <ResponsiveAppBar/>
      <Button
        className="backButton"
        onClick={handleGoBack}
        startIcon={<ArrowBackIcon />}
        variant="contained"
        color="primary"
      >
        {t("create_lesson_page.go_to_class")}
      </Button>

      <div className="class-form-container">
        <h2 className="form-header">{t("create_lesson_page.title")}</h2>
        <form className="class-form" onSubmit={handleSubmit}>
          <input
            className="input-field"
            type="text"
            placeholder={t("create_lesson_page.lesson_name_placeholder")}
            value={lessonName}
            onChange={(e) => setLessonName(e.target.value)}
          />
          <input
            className="input-field"
            type="text"
            placeholder={t("create_lesson_page.video_link_placeholder")}
            value={videoLink}
            onChange={(e) => setVideoLink(e.target.value)}
          />
          <label className="checkbox-container">
            {t("create_lesson_page.public_lesson_prefix")} ({isPublicLesson ? t("create_lesson_page.public_lesson_description") : t("create_lesson_page.private_lesson_description")})

            <input
              className="checkbox"
              type="checkbox"
              checked={isPublicLesson}
              onChange={() => setIsPublicLesson(!isPublicLesson)}
            />
            <span className="checkmark"></span>
          </label>
          <button className="submit-btn" type="submit">{t("create_lesson_page.create_lesson_button")}</button>
        </form>
        <br/>
        <Divider component="div" >
          {lessonError && t("create_lesson_page.creating_lesson_error")}
        </Divider>
        <br/>
        <div
          style={{
            position: 'relative',
            width: "100%",         // Set the width based on the given width prop
            paddingTop: '56.25%', // Maintain 16:9 aspect ratio (56.25% is 9/16)
            margin: '0 auto',     // Center the container horizontally
            display: videoLoaded ? 'block' : 'none',
          }}
        >
          <ReactPlayer
            id="previewVideo"
            url={videoLink}
            ref={setRef}
            controls={true}
            onReady={handleReady}
            style={{ 
              position: 'absolute', 
              top: 0, 
              left: 0, 
              maxWidth: '100%', 
              maxHeight: '100%' 
            }}
          />
        </div>
      </div>
    </>
  )
}
