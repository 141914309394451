import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate, useLocation } from 'react-router-dom';
import { signOut } from "firebase/auth";
import { auth } from '../firebase';
import { useTranslation } from 'react-i18next';

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [userEmail, setUserEmail] = React.useState("");
  const [settings, setSettings] = React.useState({});
  const [pages, setPages] = React.useState({});

  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleLogout = () => {    
    console.log("NavBar: handling logout")           
    signOut(auth).then(() => {
      // Sign-out successful.
      setIsLoggedIn(false)
      console.log("Signed out successfully")
      navigate("/");
    }).catch((error) => {
      // An error happened.
      console.log("Signout error has occured")
    });
  }

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (page) => {
    setAnchorElNav(null);
    if (page) {
      console.log("NavBar: Going to page:", page)
      navigate("/" + page);
    } else {
      console.log("NavBar: empty page")
    }
  };

  const handleCloseUserMenu = (setting) => {
    setAnchorElUser(null);
  };

  const onClickOnButton = (setting) => {
    setAnchorElUser(null);
    console.log("NavBar: Going to setting:", setting)
    if (setting == "logout") {
      handleLogout()
    } else {
      navigate("/" + setting);
    }
  };

  useEffect(() => {
    auth.authStateReady().then(() => {
      // Do whatever you want here ...
      var user = auth.currentUser;
      console.log("NavBar: Checking if logged in:", user)
      if (user !== null) {
        console.log("Logged in:", user.email, user.getIdToken())
        setIsLoggedIn(true)
        // The user object has basic properties such as display name, email, etc.
        // const displayName = user.displayName;
        setUserEmail(user.email);
        // const photoURL = user.photoURL;
        // const emailVerified = user.emailVerified;

        // The user's ID, unique to the Firebase project. Do NOT use
        // this value to authenticate with your backend server, if
        // you have one. Use User.getToken() instead.
        const uid = user.uid;
        setSettings({"profile": t("nav_bar.profile"), "logout": t("nav_bar.logout")})
        setPages({"about": t("nav_bar.about"), "classes": t("nav_bar.classes"), "watch_lesson": t("nav_bar.watch_lesson")})
        console.log("pages:", pages)
      } else {
        setIsLoggedIn(false)
        setSettings({})
        setPages({"about": t("nav_bar.about"), "watch_lesson": t("nav_bar.watch_lesson")})
      }

    });
  }, []);
  

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            VERIVID
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: (isLoggedIn ? 'flex' : 'none'), 
                                             md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={() => handleCloseNavMenu()}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {Object.entries(pages).map((page_item) => (
                <MenuItem key={page_item[0]} onClick={() => handleCloseNavMenu(page_item[0])}>
                  <Typography textAlign="center">{page_item[1]}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            VERIVID
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {Object.entries(pages).map((page_item) => (
              <Button
                key={page_item[0]}
                onClick={() => handleCloseNavMenu(page_item[0])}
                sx={{ my: 2, color: 'white', display: 'block', margin: "5px", background: "#357ef2" }}
              >
                {page_item[1]}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            {
              (!isLoggedIn && location.pathname !== "/login") &&
              <Button
                key={"login"}
                onClick={() => handleCloseNavMenu("login")}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {t("nav_bar.login")}
              </Button>

            }
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            {isLoggedIn && 
              <Tooltip title={t("nav_bar.open_settings")}>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt={userEmail} />
                </IconButton>
              </Tooltip>
            }
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {Object.entries(settings).map((setting_item) => (
                <MenuItem key={setting_item[0]} onClick={() => onClickOnButton(setting_item[0])}>
                  <Typography textAlign="center">{setting_item[1]}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
