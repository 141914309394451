import React, {useState, useEffect, useRef} from 'react';
import ResponsiveAppBar from '../components/ResponsiveAppBar';
import './ClassForm.css';
import { auth } from '../firebase';
import { useNavigate } from "react-router-dom";
import '../components/backButton.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { Container, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import emailjs from '@emailjs/browser';
import { Tooltip } from 'react-tooltip'

const selectStyle = {
  width: '100%',
  padding: '8px',
  margin: '8px 0',
  boxSizing: 'border-box',
  border: '1px solid #ccc',
  borderRadius: '4px',
};


export default function ContactUsPage() {
  const [title, setTitle] = useState('');
  const [topic, setTopic] = useState('');
  const [description, setDescription] = useState('');
  const [users, setUsers] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [userEmail, setUserEmail] = useState('');
  const [userToken, setUserToken] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const [sendError, setSendError] = useState(false);
  const [sentFlag, setSentFlag] = useState(false);
  const [hoverSubmit, setHoverSubmit] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const form = useRef();


  const teamMembers = [
    {
      name: t("contact_us_page.member_list.first_member"),
      title: t("contact_us_page.member_list.first_title"),
      imageUrl: '/daniel.jpeg'
    },
    {
      name: t("contact_us_page.member_list.second_member"),
      title: t("contact_us_page.member_list.second_title"),
      imageUrl: '/yonatan.jpeg'
    }
  ];

  useEffect(() => {
    auth.authStateReady().then(() => {
      // Do whatever you want here ...
      var user = auth.currentUser;
      if (user !== null) {
        setUserEmail(user.email)
        setUserToken(user.accessToken)
      } else {

      }
    })
  })


  const validate = () => {
    const newErrors = {};

    if (!title) newErrors.title = t("contact_us_page.form_errors.required_title");
    if (!topic) newErrors.topic = t("contact_us_page.form_errors.required_topic");
    if (!description) newErrors.description = t("contact_us_page.form_errors.required_description");

    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Sending review")
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setFormErrors(validationErrors);
      return
    } 
    setFormErrors({});

    var templateParams = {
      from_name: userEmail,
      title: title,
      topic: topic,
      description: description
    };
    emailjs
      .send('service_a3bdsmi', 'template_8owbt6p', templateParams, {
        publicKey: '0fjKXtR7L26ngGHG-',
      })
      .then(
        () => {
          setSendError(false)
          setSentFlag(true)
        },
        (error) => {
          console.log('FAILED...', error.text);
          setSentFlag(false)
          setSendError(true)
        },
      );
    
  };

  const handleMouseEnter = () => {
    setHoverSubmit(true);
    console.log("enter")
  };

  const handleMouseLeave = () => {
    setHoverSubmit(false);
        console.log("leave")

  };

  return (
    <>
      <ResponsiveAppBar/>
      <div className="class-form-container">
        <h2 className="form-header">{t("contact_us_page.title")}</h2>
        <form ref={form} className="class-form" onSubmit={handleSubmit}>
          <input
            className="input-field"
            type="text"
            placeholder={t("contact_us_page.title_placeholder")}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          {formErrors.title && <span style={{ color: 'red' }}>{formErrors.title}</span>}

          <select
            style={selectStyle}
            value={topic}
            onChange={(e) => setTopic(e.target.value)}
          >
            <option value="">{t("contact_us_page.select_topic")}</option>
            <option value="support">{t("contact_us_page.topics.support")}</option>
            <option value="feature_request">{t("contact_us_page.topics.feature_request")}</option>
            <option value="feedback">{t("contact_us_page.topics.feedback")}</option>
            <option value="security">{t("contact_us_page.topics.security")}</option>
            <option value="other">{t("contact_us_page.topics.other")}</option>
          </select>
          {formErrors.topic && <span style={{ color: 'red' }}>{formErrors.topic}</span>}

          <textarea
            className="input-field"
            placeholder={t("contact_us_page.description_placeholder")}
            rows="4"
            cols="50"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          {formErrors.description && <span style={{ color: 'red' }}>{formErrors.description}</span>}

          <Button 
            disabled={userEmail === ''} 
            className="submit-btn" 
            variant="contained" 
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            type="submit">
              {t("contact_us_page.send_button")}
              <br/>
              { userEmail === '' && t("contact_us_page.login_required_error") }
          </Button>
          
        </form>
        <br/>
        <Divider component="div" >
          {sentFlag && t("contact_us_page.sending_review_sucess") }
          {sendError && t("contact_us_page.sending_review_error")}
        </Divider>
      </div>
      <Container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px' }}>
        {teamMembers.map((member, index) => (
          <Grid container spacing={2} alignItems="center" key={index} style={{ marginBottom: '20px', maxWidth: '600px' }}>
            <Grid item xs={3}>
              <img 
                src={member.imageUrl} 
                alt={member.name} 
                style={{ width: '80px', height: '80px', borderRadius: '8px' }} 
              />
            </Grid>
            <Grid item xs={9}>
              <Typography variant="h6">{member.name}</Typography>
              <Typography variant="subtitle1">{member.title}</Typography>
            </Grid>
          </Grid>
        ))}
      </Container>
    </>
  );
}
