// Step.js
import React from 'react';
import { useSpring, animated } from 'react-spring';
import './Step.css';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTranslation } from 'react-i18next';

const Step = ({ number, title, description, onClick, isDone, removeStepHeader, isLink }) => {
  const { t } = useTranslation();

  const animationProps = useSpring({
    opacity: 1,
    transform: 'translateX(0)',
    from: { opacity: 0, transform: 'translateX(-50px)' },
    delay: 500 * number, // Adjust the delay based on step number
  });

  return (
    <animated.div style={animationProps} className={`step ${isDone ? 'done' : ''}`} onClick={onClick}>
      {isDone && (
        <div className="icon-container">
          <CheckCircleIcon className="check-icon" />
        </div>
      )}
      <div>
        { removeStepHeader ?
          <h2>{title}</h2>
          :
          <h2>{t("step_component.step_title")} {number}: {title}</h2>
        }
        <p>{description}</p>
      </div>
    </animated.div>
  );
};

export default Step;
