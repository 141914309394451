import React, { useState, useEffect } from 'react';
import { onAuthStateChanged } from "firebase/auth";
import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom';
import ResponsiveAppBar from '../components/ResponsiveAppBar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Step from '../components/Step';
import { useTranslation } from 'react-i18next';

// https://www.freecodecamp.org/news/use-firebase-authentication-in-a-react-app/

const AboutPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    console.log(t, t('welcome'))
    auth.authStateReady().then(() => {
      // Do whatever you want here ...
      var user = auth.currentUser;
      if (user !== null) {
        setIsLoggedIn(true)
      } else {
        setIsLoggedIn(false)
      }
    })
  })

  const onClick = () => {
    navigate("/contact_us/")
  }

  return (
    <>
      <ResponsiveAppBar/>
      <main className="steps-container">
        <Step number={1} 
              title={t("about_page.about_step_1")}
              isDone={false}
              removeStepHeader={true}/>
        <Step number={2} 
              title={t("about_page.about_step_2")}
              isDone={false}
              removeStepHeader={true}/>
        <Step number={3} 
              title={t("about_page.about_step_3")}
              isDone={false}
              removeStepHeader={true}/>

        <Step number={5} 
              title={t("about_page.about_step_4")}
              isDone={false}
              removeStepHeader={true}/>

        <Step number={6} 
              title={t("about_page.about_step_5")}
              isDone={false}
              removeStepHeader={true}
              onClick={onClick}
              isLink={true}/>
      </main>
    </>
  )
}
 
export default AboutPage